.pds-button,
.pds-tooltip,
#ada-entry {
  display: none !important;
}

* {
  box-shadow: none !important;
}

body {
  display: initial;
}

main {
  background: transparent !important;
}